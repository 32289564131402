import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import Background from "./background"

const Layout = ({ withBackground, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div
      className={`font-body flex flex-col justify-between h-full ${
        withBackground ? "text-white" : "text-gray-900"
      }`}
    >
      {withBackground ? <Background /> : null}
      <main>{children}</main>
      <nav>
        <ul className="flex justify-between m-5 list-none">
          <li>
            <Link
              className={`md:text-xl transition duration-150 ease-in-out ${
                withBackground ? "hover:text-teal-200" : "hover:text-blue-500"
              }`}
              to="/"
            >
              {data.site.siteMetadata.title}
            </Link>
          </li>
          <li>
            <a
              className={`md:text-xl underline transition duration-150 ease-in-out ${
                withBackground ? "hover:text-teal-200" : "hover:text-blue-500"
              }`}
              href="mailto:work@yams.studio?subject=Let's%20work!"
            >
              work@yams.studio
            </a>
          </li>
        </ul>
      </nav>
    </div>
  )
}

Layout.propTypes = {
  withBackground: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default Layout
